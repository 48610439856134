.game {
  display: grid;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  grid-template-rows: auto;
  background-color: white;
  grid-template-areas:
    "container container container leaderboard"
    "container container container leaderboard"
    "container container container leaderboard"
    "container container container leaderboard";
}

.session {
  margin-left: auto;
  margin-right: auto;
  font-size: 40px;
  text-align: center;
  margin-top: 15%;
}

.container {
  max-width: 1000px;
}

.difficulty-container {
  font-family: "Open Sans", sans-serif;
  position: relative;
  height: 95vh;
  width: 100%;
  /* vertically align its content */
  display: table;
  background: white;
}

.difficulty-container p {
  margin-left: auto;
  margin-right: auto;
  color: rgba(35, 47, 62);
  width: 50%;
  font-size: 18px;
  padding-bottom: 10px;
}

.difficulty-options {
  /* vertically align inside its parent */
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  background: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.difficulty-container h1 {
  font-size: 40px;
  color: #ffffff;
  padding-bottom: 20px;
}

.difficulty-button {
  display: inline-block;
  color: #ff9900;
  text-transform: uppercase;
  border: none;
  width: 160px;
  height: 70px;
  font-size: 20px;
  font-weight: bolder;
  margin-right: 20px;
  margin-left: 20px;
  transition: 0.4s;
}

.difficulty-button {
  background: linear-gradient(
    -90deg,
    rgba(35, 47, 62, 0.7) calc(50% - 10px),
    rgba(35, 47, 62, 0.7) calc(50% - 10px) calc(50% + 10px),
    rgba(35, 47, 62) calc(50% + 10px) 100%
  );
  background-size: 250% 100%;
  background-position: 100% 50%;

  transition: 0.5s all;
}

.difficulty-button:hover {
  background-position: 0% 50%;
  transition: 0.5s all;
}

.difficulty-options .difficulty-button {
  opacity: 0;
  -webkit-animation-duration: 0.8s;
  -moz-animation-duration: 0.8s;
  animation-duration: 0.8s;
  -webkit-animation-delay: 0.3s;
  -moz-animation-delay: 0.3s;
  animation-delay: 0.3s;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.no-cssanimations.difficulty-button {
  opacity: 1;
}

.bouncy.difficulty-button {
  -webkit-animation-name: cd-bounce-rotate;
  -moz-animation-name: cd-bounce-rotate;
  animation-name: cd-bounce-rotate;
}

.bouncy.difficulty-button {
  -webkit-animation-duration: 0.5s;
  -moz-animation-duration: 0.5s;
  animation-duration: 0.5s;
}

.difficulty-button:hover {
  background-color: rgba(4, 3, 31);
}

.bouncy.difficulty-options .difficulty-button {
  -webkit-animation-name: cd-bounce-rotate;
  -moz-animation-name: cd-bounce-rotate;
  animation-name: cd-bounce-rotate;
}

.bouncy.difficulty-options .difficulty-button {
  -webkit-animation-duration: 0.5s;
  -moz-animation-duration: 0.5s;
  animation-duration: 0.5s;
}

.bouncy.difficulty-options .cd-btn {
  -webkit-animation-delay: 0.4s;
  -moz-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

@-webkit-keyframes cd-bounce-right {
  0% {
    opacity: 0.2;
    -webkit-transform: translateX(-200px);
  }
  60% {
    opacity: 0.7;
    -webkit-transform: translateX(15px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}
@-moz-keyframes cd-bounce-right {
  0% {
    opacity: 0.2;
    -moz-transform: translateX(-200px);
  }
  60% {
    opacity: 0.7;
    -moz-transform: translateX(15px);
  }
  100% {
    opacity: 1;
    -moz-transform: translateX(0);
  }
}
@keyframes cd-bounce-right {
  0% {
    opacity: 0.2;
    -webkit-transform: translateX(-200px);
    -moz-transform: translateX(-200px);
    -ms-transform: translateX(-200px);
    -o-transform: translateX(-200px);
    transform: translateX(-200px);
  }
  60% {
    opacity: 0.7;
    -webkit-transform: translateX(15px);
    -moz-transform: translateX(15px);
    -ms-transform: translateX(15px);
    -o-transform: translateX(15px);
    transform: translateX(15px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@-webkit-keyframes cd-bounce-left {
  0% {
    opacity: 0.2;
    -webkit-transform: translateX(200px);
  }
  60% {
    opacity: 0.7;
    -webkit-transform: translateX(-15px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}
@-moz-keyframes cd-bounce-left {
  0% {
    opacity: 0.2;
    -moz-transform: translateX(200px);
  }
  60% {
    opacity: 0.7;
    -moz-transform: translateX(-15px);
  }
  100% {
    opacity: 1;
    -moz-transform: translateX(0);
  }
}
@keyframes cd-bounce-left {
  0% {
    opacity: 0.2;
    -webkit-transform: translateX(200px);
    -moz-transform: translateX(200px);
    -ms-transform: translateX(200px);
    -o-transform: translateX(200px);
    transform: translateX(200px);
  }
  60% {
    opacity: 0.7;
    -webkit-transform: translateX(-15px);
    -moz-transform: translateX(-15px);
    -ms-transform: translateX(-15px);
    -o-transform: translateX(-15px);
    transform: translateX(-15px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@-webkit-keyframes cd-bounce-rotate {
  0% {
    opacity: 0.2;
    -webkit-transform: perspective(800px) rotateX(-80deg);
  }
  20% {
    opacity: 1;
  }
  60% {
    -webkit-transform: perspective(800px) rotateX(20deg);
  }
  100% {
    opacity: 1;
    -webkit-transform: perspective(800px) rotateX(0);
  }
}
@-moz-keyframes cd-bounce-rotate {
  0% {
    opacity: 0.2;
    -moz-transform: perspective(800px) rotateX(-80deg);
  }
  20% {
    opacity: 1;
  }
  60% {
    -moz-transform: perspective(800px) rotateX(20deg);
  }
  100% {
    opacity: 1;
    -moz-transform: perspective(800px) rotateX(0);
  }
}
@keyframes cd-bounce-rotate {
  0% {
    opacity: 0.2;
    -webkit-transform: perspective(800px) rotateX(-80deg);
    -moz-transform: perspective(800px) rotateX(-80deg);
    -ms-transform: perspective(800px) rotateX(-80deg);
    -o-transform: perspective(800px) rotateX(-80deg);
    transform: perspective(800px) rotateX(-80deg);
  }
  20% {
    opacity: 1;
  }
  60% {
    -webkit-transform: perspective(800px) rotateX(20deg);
    -moz-transform: perspective(800px) rotateX(20deg);
    -ms-transform: perspective(800px) rotateX(20deg);
    -o-transform: perspective(800px) rotateX(20deg);
    transform: perspective(800px) rotateX(20deg);
  }
  100% {
    opacity: 1;
    -webkit-transform: perspective(800px) rotateX(0);
    -moz-transform: perspective(800px) rotateX(0);
    -ms-transform: perspective(800px) rotateX(0);
    -o-transform: perspective(800px) rotateX(0);
    transform: perspective(800px) rotateX(0);
  }
}
