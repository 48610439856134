.loading {
  font-size: 10px;
  font-weight: bolder;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.loading p {
  padding-top: 0px;
}

.loading-animation {
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 0;
  margin-bottom: 0;
  margin-top: 0;
}
