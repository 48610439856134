.leaderboard {
  width: 75%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.leaderboard h2 {
  margin-top: 10px;
  text-decoration: underline;
}

.leaderboard table{
  width: 100%;
}

.leaderboard th{
  text-decoration: none;
  border: 1px solid black;
}

.leaderboard td{
  text-align: left;
  border: 1px solid black;
  padding:10px;
}
.leaderboard .userName{
  text-align: center;
  border: 1px solid black;
  padding:10px;
}


.leaderboard tr{
  
}

.leaderboard table tr:hover{
  
  
}

.leaderboard a{
  color:black;
  border-radius: 5px;
  padding:10px;
  text-decoration: none;
}

.leaderboard a:hover{
  background-color: orange;
  text-decoration: none;
  color:black;
}


.leaderboard tr:nth-child(even) {
  background-color: #dddddd;
}
