.puzzle-bank img {
  height: 60%;
  width: 60%;
  display: block;
  margin: auto;
}

.puzzle-bank {
  margin-left: auto;
  margin-right: auto;
  width: 80%;
}

.puzzle-bank h1 {
  text-align: center;
}
