.profile-page {
  font-size: 30px;
  text-align: center;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.profile-page h2 {
  margin-top: 20px;
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
  width: 35%;
}

.profile-page h5 {
  margin-bottom: 15px;
  border-style: solid;
  width: 200px;
  margin-left: auto;
  margin-right: auto;
}

.avatar-image {
  width: 500px;
  height: 300px;
  border-radius: 5px;
  margin-bottom: 25px;
}

.profile-section {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: auto auto;
}

.profile-scoreboard table {
  border-collapse: collapse;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.profile-scoreboard td,
th {
  border: 1px solid #dddddd;
  text-align: center;
  font-size: 18px;
}
