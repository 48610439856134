:root {
  --tile-size: 60px;
}

.board {
  margin-top: 20px;
  text-align: center;
  margin-bottom: 20px;
}

.random {
  display: inline-block;
}

.board p {
  padding: 5px 10px;
  font-size: 15px;
  border-width: 1px;
  border-color: black;
  border-style: solid;
  border-radius: 5px;
  margin-left: auto;
  margin-right: auto;
  width: 95%;
}

.free {
  text-align: center;
  margin: auto;
  background-color: rgb(58, 96, 143, 0.6);
  padding-top: 12px;
  padding-bottom: 5px;
}

.free * {
  display: inline-block;
}

.free img {
  height: var(--tile-size);
  width: var(--tile-size);
}

.free span {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: bolder;
}

.free-piece {
  width: 80px;
  height: 80px;
  margin-top: 3px;
  margin-bottom: 3px;
}

/* .free-piece img span {
  margin-left: auto;
  margin-right: auto;
} */

.grid-board {
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  display: grid;
  background-repeat: no-repeat;
  background-size: cover;
  grid-gap: 0px;
  margin-bottom: 15px;
}

.grid-item-empty {
  width: var(--tile-size);
  height: var(--tile-size);
}

.grid-item {
  background-color: white;
  border-style: solid;
  width: var(--tile-size);
  height: var(--tile-size);
  position: relative;
}

.grid-item img {
  height: 100%;
  width: 100%;
}

.context_block {
  margin: 20px;
  border-radius: 5px;
  border: 1px solid black;
  padding: 5px 10px;
  font-size: 15px;
  width: 75%;
  margin-left:auto;
  margin-right:auto;
}

.Board_overview_header {
  font-weight: bold;
  font-size: 18px;
  text-decoration: underline;
}

.board_feedback_prompt {
  width: 100%;
  margin-top: 10px;
}

.board_feedback_prompt:focus {
  outline: none !important;
  border: 1px solid rgb(0, 0, 0);
  box-shadow: 0 0 0px #000000;
}
input:focus {
  outline: none !important;
  border-color: #719ece;
  box-shadow: 0 0 0px #719ece;
}
textarea:focus {
  outline: none !important;
  border-color: #719ece;
  box-shadow: 0 0 0px #719ece;
}

.board_score {
  font-weight: bold;
  font-size: 18px;
}

.board_submit_button {
  position: relative;
  color: #ff9900;
  text-transform: uppercase;
  border: none;
  width: 100px;
  height: 50px;
  font-size: 15px;
  font-weight: bolder;
  margin-top: 10px;
  transition: 0.4s;
  border-radius: 5px;
  float:right;
}

.board_submit_button {
  background: linear-gradient(
    -90deg,
    rgba(35, 47, 62, 0.7) calc(50% - 10px),
    rgba(35, 47, 62, 0.7) calc(50% - 10px) calc(50% + 10px),
    rgba(35, 47, 62) calc(50% + 10px) 100%
  );
  background-size: 250% 100%;
  background-position: 100% 50%;

  transition: 0.5s all;
}

.board_submit_button:hover {
  background-position: 0% 50%;
  transition: 0.5s all;
}

.board_close_button {
  position: relative;
  color: #ff9900;
  text-transform: uppercase;
  border: none;
  width: 100px;
  height: 50px;
  font-size: 15px;
  font-weight: bolder;
  margin-top: 10px;
  transition: 0.4s;
  border-radius: 5px;
  text-align: center;
  float:right;
}

.board_close_button {
  background: linear-gradient(
    -90deg,
    rgba(35, 47, 62, 0.7) calc(50% - 10px),
    rgba(35, 47, 62, 0.7) calc(50% - 10px) calc(50% + 10px),
    rgba(35, 47, 62) calc(50% + 10px) 100%
  );
  background-size: 250% 100%;
  background-position: 100% 50%;
  margin-left: 20px;
  transition: 0.5s all;
}

.board_close_button:hover {
  background-position: 0% 50%;
  transition: 0.5s all;
}

.modal-content {
  font-size: 15px;
}

.hint-button {
  height: 30px;
  width: 70px;
  border-radius: 5px;
  text-align: center;
  font-size: 15px;
  margin-right: 10px;
}

.submit-button {
  height: 30px;
  width: 70px;
  border-radius: 5px;
  text-align: center;
  font-size: 15px;
  margin-left: 10px;
}
