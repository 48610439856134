body {
  font-size: 1.6rem;
  font-family: 'Open Sans', sans-serif;
  color: #040404;
}

header {
  height: 45px;
  background: #2f3640;
}

.navigation-bar a {
  display: flex;
}

.inner-width {
  max-width: 1000px;
  padding: 0 10px;
  margin: auto;
  top: 0;
  position: absolute;
}

.navigation-menu {
  float: right;
  display: flex;
  align-items: center;
  min-height: 40px;
  box-sizing: none;
}

.navigation-menu a {
  margin-left: 10px;
  color: #ddd;
  text-transform: uppercase;
  font-size: 14px;
  padding: 12px 20px;
  border-radius: 4px;
  transition: 0.3s linear;
}

.navigation-menu a:hover {
  text-decoration: none;
  background: Orange;
  color: #2f3640;
  transform: scale(0.8);
  font-weight: bold;
}

.navigation-menu svg {
  margin-top: 2px;
  margin-right: 8px;
  font-size: 16px;
}

.menu-toggle-btn {
  float: right;
  height: 90px;
  line-height: 90px !important;
  color: #fff;
  font-size: 26px;
  display: none !important;
  cursor: pointer;
}

.game-icon {
  background-color: none;
}

.leaderboard {
  border: none;
}
/* 
  @media screen and (max-width: 700px) {
    .menu-toggle-btn {
      display: block !important;
    }
  
    .navigation-menu {
      position: fixed;
      width: 100%;
      max-width: 400px;
      background: #172b4d;
      top: 90px;
      right: 0;
      display: none;
      padding: 20px 40px;
      box-sizing: border-box;
    }
  
    .navigation-menu::before {
      content: "";
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid #172b4d;
      position: absolute;
      top: -10px;
      right: 10px;
    }
  
    .navigation-menu a {
      display: block;
      margin: 10px 0;
    }
  
    .navigation-menu.active {
      display: block;
    }
  } */
