.homepage {
  padding: 0;
  width: 100%;
  height: 80vh;
  margin-left: auto;
  margin-right: auto;
}
.b1 {
  width: 100%;
  height: 100%;
  margin: auto;
  top: 0;
  display: table;
  background-size: cover;
}
.b1 .in1 {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  max-width: none;
}
.content {
  max-width: 600px;
  margin: auto;
  text-align: center;
}
.content h1 {
  font-family: 'Century Gothic', sans-serif;
  color: black;
  font-size: 60px;
  text-shadow: 0 0 300px #000;
  padding-bottom: 15px;
}
.content .btn {
  border-radius: 9px;
  color: black;
  text-decoration: none;
  font-family: 'Century Gothic', sans-serif;
  border: 3px solid;
  padding: 7px 13px;
  font-weight: bold;
}
.content .btn:hover {
  color: orange;
}
